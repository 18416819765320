@import './styles/vdevice.less';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
  @apply relative h-screen;
  background: var(--v-bg-primary);
  color: var(--v-txt-primary);

  margin: 0;
  font-family: var(--v-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--v-font-size-h6);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: none;
}

.vdir-rtl {
  .accounts-button {
    padding: 10px;
  }
}

